function floatingChatIcon() {

	const chatIconButton = document.getElementById("floatChatIcon");
	const chatTextPopup = document.getElementById("chatTextPopup");
	const closeIcon = document.getElementById("closeIcon");

	function reveal(chatTextPopup, delay) {

		setTimeout(() => {
			chatTextPopup.classList.remove("invisible");
		}, delay);
	}
	reveal(chatTextPopup, 1250)

	chatIconButton.addEventListener("click", () => {
		if (chatTextPopup.classList.contains("invisible")) {
			chatTextPopup.classList.remove("invisible");
		} else {
			chatTextPopup.classList.add("invisible");
		}
	})

	closeIcon.addEventListener("click", function(e) {
		chatTextPopup.classList.add("invisible");
	})
}
export default floatingChatIcon;